
.backgroundBoxParent {
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
    height: 20em;
    /* make height smaller to make hard border */
    /* height: 14em; */
}  

.backgroundBox {
    /* set height of background image */
    height: 9em;
    filter: blur(14px) brightness(65%);
    -webkit-filter: blur(14px) brightness(65%);
    transform: scale(2.33);
}

.goInForground {
    position: relative;
}

.heading {
    margin-top: -1em;
    color: white;
}

.pagePaddingTop {
    padding-top: 3em;
}