.bigMargin {
    margin-top: 1.5em;
}

.bigMarginBottom {
    margin-bottom: 1.5em;
}

.textAlignMiddle {
    text-align: center;
}

.infoButtonDiv {
    display: inline;
    height: 100%;
}

.floatLeft {
    float: left;
}