
.searchFormRow {
    margin-bottom: 2.5em;
}

.searchInputCol {
    position: relative;
    min-height: 5.0em;
}

.searchButtonCol {
    position: relative;
    min-height: 3.5em;
}

.searchButton, .searchTextInput {
    position: absolute;
    bottom:0;
}

.searchButton {
    left: 0.5em;
}

.searchTextInput {
    min-width: 2em;
    right:0.5em;
    left:0.5em;
    width: calc(100% - 1em);
}

.invalidInputFeedback {
    position: absolute;
    bottom:-2em;
}