.recentGameLink:hover, .leftArrow:hover, .rightArrow:hover {
    transition: 0.3s;
    filter: brightness(80%);
    color: black;
}

.recentGameLink {
    color: black;
    text-decoration: none;
}

.gameIndexBadge {
    position:relative;  
    top: 3em;
    right: -1.2em;
    z-index: 10;
    --bs-bg-opacity: 0.75 !important;
}

.gameIndexBadge :hover {
    color: rgb(175, 175, 175) !important;
}

.carouselItem {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 25px;
}

.gameIndexBadge a {
    color: white;
    font-weight: normal;
}