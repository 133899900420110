
$primary: #442D53;
$secondary: #6c757d;
$dark-secondary: #7581852d;
$success: #198754;
$info: #0dcaf0;
$warning: #dfa90a;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;   
$body-color: #212529;

// Color used for first svg graphics: #6C63FF

//custom color to be mapped
$grey: #f8f9fab9;
$dark-grey: #3e464e;
$accent: #905db9;


// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  dark-secondary: $dark-secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  // add any additional color below
  grey: $grey,
  accent: $accent,
  body-color: $body-color,
  dark-grey: $dark-grey,
// now bg-accent,btn-accent,etc.. can be called
); 

@import '~bootstrap/scss/bootstrap.scss';
