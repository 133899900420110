.listMargin {
    margin-left: 0.6em;
    margin-top: 1.1em;
}

.listItemMargin {
    margin-bottom: 0.5em;
}

.iconMargin {
    margin-right: 0.6em;
}