.button {
    margin-right: 0.4em;
}

.buttonCol {
    text-align: center;
}

.buttonDevision {
    display: inline;
    position: relative;
    margin-left: 0.3em;
    margin-right: 0.8em;
    top: 0.4em;
}