.headerTopMargin {
    margin-top: 3em;
}

.smily {
    height: 100%;
    width: 85%;
    color: var(--bs-success);
}

.thanksContainer {
    position: relative;
}

.smilyDiv {
    width: 10%;
    display: inline-block;
    position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.thanksDiv {
    margin-left: 10%;
    width: calc(100% - 10%);
    display: inline-block;
}