.dropdownItem {
  line-height: 2.4em;
  text-align: center !important;
  vertical-align: middle;
}

.dropdownItem a {
    padding: 0em;
}

.dropdownMenuBtn button:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.dropdownMenuBtn button:active {
  background-color: transparent !important;
  border: none;
}

.dropdownMenuBtn div {
  right: -0.3em;
}

.dropdownMenuBtn a {
  color: var(--bs-grey) !important;
}

.selectedLanguage {
  font-weight: bold !important;
}

.languagesIcon {
    color: var(--lightBlue);
}
