.footerDiv {
  margin-top: 5vh;
  height: 7vh;
  width: 100%;
  background-color: var(--bs-primary);
}

.footerList {
    list-style: none;
    margin: 0;
    padding-top: 0.7em;
    padding-left: 0;
}

.footerList li {
    display: inline;
    margin-right: 1em;
}

.footerList li a {
    color: var(--bs-grey) !important;
    text-decoration: none;
}

.footerList li a:hover {
    filter: brightness(70%);
    transition: 0.15s;
}