.carouselContainer {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
}

.carouselWrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carouselContentWrapper {
    /* overflow: hidden; */
    width: 100%;
    height: 100%;
     overflow-y: hidden;  
	 overflow-x: visible; 
     /* Hide scrollbar for IE, Edge and Firefox */
     -ms-overflow-style: none;  
     scrollbar-width: none; 
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.carouselContentWrapper::-webkit-scrollbar {
    display: none;
}

.carouselContent {
    display: flex;
    transition: all 250ms linear;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carouselContent::-webkit-scrollbar, .carouselContent::-webkit-scrollbar {
    display: none;
}

.carouselContent > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carouselContent.show1 > * {
    width: 100%;
}

.carouselContent.show2 > * {
    width: 50%;
}

.carouselContent.show3 > * {
    width: calc(100% / 3);
}

.carouselContent.show4 > * {
    width: calc(100% / 4);
}

.leftArrow, .rightArrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 58px;
    height: 58px;
    border-radius: 34px;
    background-color: white;
    border: 1px solid #ddd;
}

.leftArrow {
    left: 24px;
}

.rightArrow {
    right: 24px;
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}
