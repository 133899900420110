.imageHeight {
    width: 100%;
}

.headingContainer {
    margin-top: 0.35em;
}

.fullHeight {
    height: 100%;
}

.gameIndexBadge {
    position: absolute;
    font-weight: normal;
    top: 1em;
    right: 1em;
}

.gameIndexBadge a {
    color: white;
    text-decoration: none;
}
.cardBody {
    padding-bottom: 0 !important;
}

.scoreDisplayDiv {
    margin-top: 0.7em;
}

.releaseParagraph {
    margin-bottom: 0;
}