.heading {
    margin-top: 2.4em;
    margin-bottom: 1.5em;
}

.scollableNavBar {
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap !important;
    display: -webkit-box !important;  
    display: -moz-box !important;   
 }