.noPadding {
    padding: 0 !important;
}

.bigMarginBottom {
    margin-bottom: 3em;
}

.faCheckIcon {
    margin-right: 0.4em;
}

.ListGroupItemPadding {
    padding-left: 1.2em;
    padding-right: 1.2em;
}