
.floatRight {
  float: right;
}

.textAlignRight {
  text-align: right;
}

.buttonMarginRight {
  margin-right: 0.5em;
}

.noListStyle {
  list-style: none;
  padding: 0;
}

.greenIcon {
  color: var(--bs-success);
}

.redIcon {
  color: var(--bs-danger);
}

.yellowIcon {
  color: var(--bs-warning);
}

.greyIcon {
  color: var(--bs-secondary);
}