
.custNavLink {
  color: var(--bs-grey) !important;
}

.alignRightFlex {
  margin-left: auto;
}

.linkItemCollapsed {
  font-size: 1.4em;
}

.navbarFormContainer {
  margin-top: 0.7em;
}

.LanguagePickerContainer {
  display: flex;
}

.searchBarDiv {
  margin-right: 1em;
}

.toggleButton {
  height: 2.0em;
  width: 3.0em;
}

.toggleButton svg {
  height: 80%;
  width: 100%;
}