.heroImage, .contributeImage, .feedbackImage {
    display: block;
    width: 90%;
    margin: auto;
}

.heroImage {
    display: block;
    height: 100%;
    margin: auto;
}


.presentationRow {
    color: white;
    padding-top: 2em !important;
    min-height: 32em;
}

.presentationHeadingDiv {
    margin-top: 2.7em;
}

.presentationDescriptionDiv {
    padding-bottom: 2em !important;
}



.recentReviewRow {
    margin-bottom: 2em;
}

.recentReviewsBackground {
    top: 30em;
    left: 0;
    background-color: rgb(169, 109, 211);
    background-image: linear-gradient(to bottom right, rgb(#92889b), rgb(70, 155, 0));
    width: 100%;
    z-index: -1;
}



.collaborationRow, .feedbackRow {
    margin-top: 5.5em !important;
    margin-bottom: 8em;
}

.contributeImage {
    margin-top: 2em;
    width: 85%;
}

.contributeHeadingDiv {
    margin-top: 2.5em;
}

.contributeButton, .feedbackButton {
    margin-top: 0.8em;
}



.feedbackImage {
    margin-top: 3em;
    margin-bottom: 3em;
}