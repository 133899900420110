.scollableNavBar {
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap !important;
    display: -webkit-box !important;  
    display: -moz-box !important;   
    margin-bottom: 0px !important;
 }

 .tabContainer {
     border-width:  1.3px;
     border-top: 0px;
     border-style: solid;
     border-color: inherit;
     border-color: var(--bs-dark-secondary);
     border-radius: 0 0 8px 8px;
     min-height: 10em;
     padding: 1em;
     margin-bottom: 2em;
     /* margin-top: 0.3em; */
 }