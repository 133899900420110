.modalButton {
    height: 1.9em;
    width: 2.5em;
    padding: 2px;
    position: relative;
}

.chagedTextIcon {
  color: var(--bs-success);
}

.modalButtonIcon {
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

    height: 1.3em;
    width: 1.3em;
}