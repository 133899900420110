.referencesList {
    counter-reset: item;
    list-style-type: none;
}

.referencesList li {
    /* list-style-type: lower-alpha; */
}

.referencesList li:before {
    content: '[' counter(item, decimal) '] ';
    counter-increment: item;
    color: #6c757d;
  }


  .referencesList li:target {
    background-color: rgba(87, 87, 87, 0.2);
}