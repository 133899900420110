.headingDiv {
    margin-bottom: 0em;
}

.gameLink {
    color: black;
    text-decoration: none;
}

.gameLink:hover {
    transition: 0.3s;
    filter: brightness(80%);
    color: black;
}

.gameCardsRow {
    margin-bottom: 1em;
}

.nrGamesFoudCol {
    margin-top: 1.5em;
    position: relative;
}

.nrGamesFoudCol p {
    position: absolute;
    bottom:0;
    margin-bottom: 0px;
}

.orderButtonCol {
    text-align: right;
}

.orderingButton {
    margin-left: 0.3em;
    margin-top: 0.3em;
}

.aboveResultsLine {
    margin-top: 0.5em;
}

.filterButtonCol {
    min-height: 3em;
    position: relative;
}

.activeFilterBtnRow {
    margin-bottom: 0.7em;
}