.heading {
    margin-top: 3em;
}

.localListStyle {
    margin-bottom: 0;
    display: inline;
}

.rowMarginTop {
    margin-top: 1.3em;
}

.hrLine {
    margin-bottom: 2.5em;
}


.markAnswer {
    color: green;
    font-weight: bold;
}

.badgeMarginRight {
    margin-right: 0.7em;
}

.icon {
    display: inline-block;
    position: relative;
    bottom: 3px;
    left: 7px;
    margin-right: 0.5em;
}

.answerDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.metricModalDiv {
    padding-right: 1em;
}

.answerIconDiv {
    width: 1.5em;
}

.answerStringDiv {
    max-width: calc(100% - 3.8em);
    width: auto;
}

.answerTextDiv {
    width: 2.2em;
}