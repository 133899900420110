.navbarFormCollapsed {
  /* width: calc(100% - 310px); */
  width: 100%;
  min-width: 3em;
}

.collapsedFormInput {
  width: 90% !important;
}

.gameNameInput {
  width: 14em !important;
}

/* Dropdown Menu */

.dropdownMenu {
  /* min-width: 30em !important; */
  max-width: 100% !important;
}

.collabsedDropdownMenu {
  min-width: 35em !important;
}

.gameImage {
  /* width: 10em; */
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.gameTextContainer {
  height: 100%;
  justify-content:center;
  display: flex;
  display: table;
  text-align: center;
}

.gameText {
  white-space: normal !important;
  margin-left: 1.5em;
  display: table-cell;
  vertical-align: middle;
}

.hline {
  margin-top: 0.0em;
  margin-bottom: 0.0em;
}