.infoRowMargin {
    margin-top: 1em  !important;
}

.heading {
    margin-top: 2em;
    margin-bottom: 1.5em;
}

.answerDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.answerTextDiv {
    width: 2.2em;
}