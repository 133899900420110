/* .answerButton {
    margin-right: 0.2em;
    margin-bottom: 0.2em;
} */

.label {
    display: block;
}

.column {
    margin-bottom: 1.5em;
}

.rangeLabelRight {
    text-align: right;
}