.offcanvas {
    /* width: 700px; */
    height: 70%;
}

.filterButton {
    position: absolute;
    bottom:0;
    margin-bottom: 0px;
}

.tabContentRow {
    /* padding-top: 2em; */
    padding: 2em;
}