
.backgroundBoxParent {
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -10;
    /* height: 100em; */
    /* make height smaller to make hard border */
    /* height: 14em; */
}  

.backgroundBox {
    /* set height of background image */
    /* height: 9em; */
    filter: blur(14px) brightness(65%);
    -webkit-filter: blur(14px) brightness(65%);
    transform: scale(2.33);
}