.link {
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    padding: 0.35em;
    margin-right: 0.3em;
    padding-top: 0.15em;
    font-size: 1.5em;
}

.link:focus, .link:hover {
    color: white;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 5s ease-in-out;
}