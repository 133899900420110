.floatLeft {
    float: left;
}

.verticalCenter {
    margin-top:auto;
    margin-bottom: auto;
}

.textAlingLeft {
    text-align: left;
}

.verticalCenter {
    margin-top:auto;
    margin-bottom: auto;
}

.flex {
    display: flex;
}

.explanationIcon {
    /* width: 5em; */
    max-width: 2em;
    min-width: 2em;
    height: 1.4em;
    margin-right: 0.7em;
    padding: 0.05em;
    /* flex-basis:5em; */
}

.explanationParagraph {
    margin-top: 1em;
    margin-bottom: 1em;
}

.modalOpenIcon {
    width: 1.3em;
    height: 1.3em;
    padding-bottom: 10%;
}

.explanationImage {
    break-before: always;
    width: calc(100% - 2.7em);
    margin-left: 2.7em;
    flex-basis: 100%;
}

.imageReference {
    margin-left: 2.7em;
}

.noListStyle {
    list-style: none;
}

.noPadding {
    padding: 0;
}

.greyOverlay {
    opacity: 0.5;
}

.elab_link {
    display:inline-block
}

.textButton {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-align: left; 
}
