.scoreDisplayContainer {
    display: inline-grid;
    grid-template-columns: 1fr;
    margin-bottom: 0.8em;
}

.heading {
    color: white !important;
    font-size: 1.2em;
    margin-bottom: 0.4em;
    display: inline-block;
    margin-top: 0.7em;
    border-radius: 100px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    background-color:  var(--bs-dark-grey);
    /* border-style: solid; */
}

.svgDiv {
    display: flex;
    justify-content: center;
}
